
$my-blue: (
  50: #5238DF,
  100: #5238DF,
  200: #5238DF,
  300: #5238DF,
  400: #5238DF,
  500: #5238DF,
  600: #5238DF,
  700: #5238DF,
  800: #5238DF,
  900: #5238DF,
  A100: #5238DF,
  A200: #5238DF,
  A400: #5238DF,
  A700: #5238DF,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  )
);

$my-accent: (
  50: #fff,
  100: #fff,
  200: #fff,
  300: #fff,
  400: #fff,
  500: #fff,
  600: #fff,
  700: #fff,
  800: #fff,
  900: #fff,
  A100: #fff,
  A200: #fff,
  A400: #fff,
  A700: #fff,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  )
);


// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$bugprog-enterprise-primary: mat.define-palette($my-blue, 100);
$bugprog-enterprise-accent: mat.define-palette($my-accent, 100, 50, 200);

// The warn palette is optional (defaults to red).
$bugprog-enterprise-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$bugprog-enterprise-theme: mat.define-light-theme((
  color: (
    primary: $bugprog-enterprise-primary,
    accent: $bugprog-enterprise-accent,
    warn: $bugprog-enterprise-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bugprog-enterprise-theme);

/* You can add global styles to this file, and also import other style files */

:root {
  --primary-color: #5238DF;
  --primary-color-darker: #5238DF;
  --primary-color-lighter: #5238DF;
  --secondary-color: #5238DF;
  --secondary-color-darker: #5238DF;
  --secondary-color-lighter: #5238DF;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
}

.noscroll {
overflow-y: hidden;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: transparent!important;
}
