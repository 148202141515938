//X-Small             None  0–576px
//Small 	            sm 	  ≥576px
//Medium 	            md 	  ≥768px
//Large 	            lg 	  ≥992px
//Extra large 	      xl 	  ≥1200px
//Extra extra large 	xxl 	≥1400px


// Custom Bootstrap CSS
$primary: #5238DF;
$accordion-icon-active-color: $primary;
@import "../node_modules/bootstrap/scss/bootstrap";

:root {
  --ti-cursor-color: dodgerblue;
  --ti-cursor-font-weight: 500;
  --tertiary: #0B1F41;
  --swiper-theme-color: var(--bs-primary);
}

.mat-mdc-unelevated-button:not(:disabled) {
  background-color: var(--bs-primary) !important;
}

.bg-primary {
  background-color: var(--bs-primary) !important;
}

.mat-mdc-fab, .mat-mdc-mini-fab {
  background-color: var(--bs-primary) !important;
}

.ti-cursor {
  background: none !important;
  -webkit-text-fill-color: black;
}

h1, h2, h3, h5, h6, p, button, li, label {
  font-family: 'Inter', sans-serif;
}

h1, h2 {
  font-size: 4.5em;
  font-weight: 900;
}

h3 {
  font-size: 3.5vw;
  font-weight: 900;
}

p, li {
  font-weight: 500;
}

.text-gray {
  color: var(--bs-gray-600);
}

button {
  font-weight: 400;
}

.text-span {
  background-image: linear-gradient(270deg, #5238df, #65a3ff 45%, var(--bs-primary));
  background-size: 10em;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-tertiary {
  background-color: var(--tertiary);
}

.text-tertiary {
  color: var(--tertiary);
}

.break-line {
  white-space: pre-line;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 3em;
  }
  h3 {
    font-size: 2em;
  }
}
